import { Component, OnInit, Inject } from "@angular/core";
import { FireservService } from "../fireserv.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-partenaires",
  templateUrl: "./partenaires.component.html",
  styleUrls: ["./partenaires.component.css"]
})

@Inject(DOCUMENT)

export class PartenairesComponent implements OnInit {

  constructor(public firebaseService: FireservService) { }

  public partners;

  ngOnInit() {
    this.partners = [];

    this.firebaseService.getPeople(this.partners, "/partners").then(data => data.map(x => {
      console.log(x.payload.doc.data());
    }));
  }

  gotoTop(tag) {
    const el = document.getElementById(tag);
    el.scrollIntoView();
  }

}
