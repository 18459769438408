import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { FireservService } from "../../fireserv.service";

interface UserFileUpload {
  path?: string;
  file?: File;
}

interface Newfile {
  name?: string;
  pic?: any;
  title?: string;
  url?: string;
}

@Component({
  selector: "app-edit-formations",
  templateUrl: "./edit-formations.component.html",
  styleUrls: ["./edit-formations.component.css"]
})
export class EditFormationsComponent implements OnInit {

  @Input() formations;
  @Input() type;

  public editformationForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
  });

  // public formations: Newfile[];
  public formFileUpload: UserFileUpload;
  public formationLinks: string[];

  constructor(public firebaseService: FireservService) { }

  ngOnInit() {
    // this.formations = [];
    this.formationLinks = [];
    this.formFileUpload = {};
  }

  delete(doc) { this.firebaseService.deleteFromID(this.type, doc).then(res => console.log("done")); }

  editFormation(id, value, event, formation?) {
    event.preventDefault();
    const data = {
      name: value.name !== null ? value.name : formation.name,
      pic: this.formFileUpload.file ? this.formFileUpload : formation.pic,
      title: value.title !== null ? value.title : formation.title
    };

    console.log({data, formations: this.formations, formation, file: this.formFileUpload.path !== undefined});

    if (this.type === "formations") {
      return this.firebaseService.editFormation(id, {
        name: data.name,
        pic: data.pic,
        title: data.title
      }, this.formFileUpload.path !== undefined);
    }
    if (this.type === "parcours") {
      return this.firebaseService.editParcours(id, {
        name: data.name,
        pic: data.pic,
        title: data.title
      }, this.formFileUpload.path !== undefined);
    }
  }

  upload(event, ref: UserFileUpload, path: string) {
    console.log(ref);
    ref.path = `${path}/${Date.now()}_${event.target.files[0].name}`;
    ref.file = event.target.files[0];
  }

  reset(form) {
    form.reset();
  }
}
