import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { AuditComponent } from "./audit/audit.component";
import { CoachingComponent } from "./coaching/coaching.component";
import { TeamComponent } from "./team/team.component";
import { PartenairesComponent } from "./partenaires/partenaires.component";
import { VisioComponent } from "./visio/visio.component";
import { AdminComponent } from "./admin/admin.component";
import { PressComponent } from "./press/press.component";
import { FormationsComponent } from "./formations/formations.component";
import { MentionsComponent } from "./mentions/mentions.component";
import { PhototekComponent } from "./phototek/phototek.component";
import { PolitiqueComponent } from "./politique/politique.component";
import { CgvComponent } from "./cgv/cgv.component";
import { RseComponent } from "./components/rse/rse.component";
import { DatesComponent } from "./components/dates/dates.component";
import { ParcoursComponent } from "./components/parcours/parcours.component";



const routes: Routes = [
  {path: "", component: HomeComponent },
  {path: "audit", component: AuditComponent },
  {path: "phototeque", component: PhototekComponent },
  {path: "coaching", component: CoachingComponent },
  {path: "team", component: TeamComponent },
  {path: "dates", component: DatesComponent },
  {path: "partenaires", component: PartenairesComponent },
  {path: "visio", component: VisioComponent },
  {path: "admin", component: AdminComponent },
  {path: "press", component: PressComponent },
  {path: "parcours", component: ParcoursComponent },
  {path: "formation", component: FormationsComponent },
  {path: "mentions", component: MentionsComponent },
  {path: "politique", component: PolitiqueComponent },
  {path: "cgv", component: CgvComponent },
  {path: "rse", component: RseComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
