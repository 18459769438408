import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from "rxjs/operators";

interface Detail {
  main: string;
  sec: string;
}

interface Newfile {
  name?: string;
  pic?: any;
  title?: string;
  url?: string;
}

interface MateSingle {
  name: string;
  pic: any;
  title: string;
  desc: string;
  details: string;
  profiles: string;
  comps: string;
  certifs: string;
  formations: string[];
}

interface Profiles {
  classes: string;
  content: string[];
}

interface Mate {
  name: string;
  pic: File;
  title: string;
  desc: string;
  details: Detail[];
  profiles: Profiles;
  comps: Profiles;
  certifs: Profiles;
  formations: Profiles;
}

interface UserFileUpload {
  path: string;
  file: File;
}

@Injectable({
  providedIn: "root"
})

export class FireservService {

  public userFileUpload: UserFileUpload;

  constructor(public db: AngularFirestore, private storage: AngularFireStorage) {}

  up(data: Newfile, ref: string) {
    const path = data.pic.path;

    console.log({uploading: data, ref, store: this.storage});

    this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
      finalize(() => {
        const storef = this.storage.ref(path);
        const fileUpload: Newfile = {
          url: "",
          name: "",
          pic: "",
          title: "",
        };

        console.log(storef);

        const formeddata = data;

        storef.getDownloadURL().subscribe(downloadURL => {
          formeddata.pic = downloadURL;
          console.log("File available at", downloadURL);
          fileUpload.url = downloadURL;
          return this.db.collection(ref).add(formeddata);
        });
      })
    ).subscribe();
  }

  formatContent(data: string) {
    if (!data) { return; }
    const arrayLines: string[] = data.split("\n");
    return arrayLines;
  }
  formatDetail(data: string) {
    // return if invalid data
    if (!data || data.length === 0) { return; }

    const arrayLines: string[] = data.split("\n");
    const arraySec: {main: string, sec: string}[] = [];
    if (arrayLines[1] && arrayLines[1].length > 0) { arrayLines.map(x => arraySec.push({main: x.split(":")[0], sec: x.split(":")[1]})); }
    return arraySec;
  }

  create(collection, data) {
    return this.db.collection(collection).add(data);
  }

  createUser(data: MateSingle) {

    const path = data.pic.path;
    this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
      finalize(() => {
        const storef = this.storage.ref(path);
        const fileUpload: Newfile = {};

        storef.getDownloadURL().subscribe(downloadURL => {
          console.log("File available at", downloadURL);
          fileUpload.url = downloadURL;

          const theData = {
            name: data.name,
            pic: downloadURL,
            title: data.title,
            desc: data.desc,
            details: this.formatDetail(data.details),
            profiles: {
              classes: "hide",
              content: this.formatContent(data.profiles)
            },
            comps: {
              classes: "hide",
              content: this.formatContent(data.comps)
            },
            certifs: {
              classes: "hide",
              content: this.formatContent(data.certifs)
            },
            formations: {
              classes: "hide",
              content: data.formations
            },
          };

          console.log(theData);

          return this.db.collection("/mates").add(theData);
        });
      })
    ).subscribe();
  }

  createFormation(data: any) { this.up(data, "/formations"); }
  createParcours(data: any) { this.up(data, "/parcours"); }

  createRse(data: any) { this.up(data, "/rse"); }

  addPhototek(data: any) { this.up(data, "/phototek"); }

  createatelier(data: any) { this.up(data, "/ateliers"); }

  createPartner(data: any) { this.up(data, "/partners"); }

  getPeople(array: any, ref: string, classes?: boolean) {

    return new Promise<any>((resolve, reject) => {
      return this.db.collection(ref).snapshotChanges()
      .subscribe(snapshots => {

        for (const iterator of array) {
          array.pop();
        }

        snapshots.map((x: any) => {
          const el = x.payload.doc.data();
          el.id = x.payload.doc.id;
          el.link = x.payload.doc.id;
          if (classes) { el.classes = "hide"; }
          array.push(el);
        });
        resolve(snapshots);
      });
    });
  }

  editUser(id, data) {

    if (data.details.length > 0) {
      const newDetails = [];
      data.details.map(x => newDetails.push({main: x.split(":")[0], sec: x.split(":")[1]}));
      data.details = newDetails;
    } else {data.details = [""]; }

    if (data.pic.path) {
      const path = data.pic.path;
      this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
        finalize(() => {
          const storef = this.storage.ref(path);
          const fileUpload: Newfile = {};

          storef.getDownloadURL().subscribe(downloadURL => {
            console.log("File available at", downloadURL);
            fileUpload.url = downloadURL;

            const theData = {
              name: data.name,
              pic: downloadURL,
              title: data.title,
              desc: data.desc,
              details: this.formatDetail(data.details),
              profiles: data.profiles,
              comps: data.comps,
              certifs: data.certifs,
              formations: {
                classes: "hide",
                content: data.formations
              },
            };

            console.log(theData);

            return this.db.collection("mates").doc(id).set(theData);
          });
        })
      ).subscribe();
    } else {

      const theData = {
        name: data.name,
        pic: data.pic,
        title: data.title,
        desc: data.desc,
        details: data.details,
        profiles: data.profiles,
        comps: data.comps,
        certifs: data.certifs,
        formations: {
          classes: "hide",
          content: data.formations
        },
      };

      console.log({theData, data});

      return this.db.collection("mates").doc(id).set(theData);
    }
  }

  editAtelier(id, data) {

    const path = data.pic.path;
    this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
      finalize(() => {
        const storef = this.storage.ref(path);
        const fileUpload: Newfile = {};

        storef.getDownloadURL().subscribe(downloadURL => {
          console.log("File available at", downloadURL);
          fileUpload.url = downloadURL;

          const theData = {
            name: data.name,
            pic: downloadURL,
            title: data.title
          };

          console.log(theData);

          return this.db.collection("ateliers").doc(id).set(theData);
        });
      })
    ).subscribe();
  }

  editFormation(id, data, pic: boolean) {

    if (pic) {
      const path = data.pic.path;
      this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
        finalize(() => {
          const storef = this.storage.ref(path);
          const fileUpload: Newfile = {};

          storef.getDownloadURL().subscribe(downloadURL => {
            console.log("File available at", downloadURL);
            fileUpload.url = downloadURL;

            const theData = {
              name: data.name,
              pic: downloadURL,
              title: data.title
            };

            console.log(theData);

            return this.db.collection("formations").doc(id).set(theData);
          });
        })
      ).subscribe();
    } else {
      const theData = {
        name: data.name,
        pic: data.pic,
        title: data.title
      };

      console.log(theData);

      return this.db.collection("formations").doc(id).set(theData);
    }
  }
  editParcours(id, data, pic: boolean) {

    if (pic) {
      const path = data.pic.path;
      this.storage.upload(path, data.pic.file).snapshotChanges().pipe(
        finalize(() => {
          const storef = this.storage.ref(path);
          const fileUpload: Newfile = {};

          storef.getDownloadURL().subscribe(downloadURL => {
            console.log("File available at", downloadURL);
            fileUpload.url = downloadURL;

            const theData = {
              name: data.name,
              pic: downloadURL,
              title: data.title
            };

            console.log(theData);

            return this.db.collection("/parcours").doc(id).set(theData);
          });
        })
      ).subscribe();
    } else {
      const theData = {
        name: data.name,
        pic: data.pic,
        title: data.title
      };

      console.log(theData);

      return this.db.collection("/parcours").doc(id).set(theData);
    }
  }

  getSnapshot(collection: string) {
    return this.db.collection(collection).snapshotChanges();
  }

  getSpecific(collection, doc) {
    const docRef = this.db.collection(collection).doc(doc);

    const document = docRef.get();
    return document;
  }
  // Update
  update(collection: string, data, upd) {
    return this.db
      .collection(collection)
      .doc(data.payload.doc.id)
      .set(upd, { merge: true });
  }

  // Delete
  delete(collection: string, data) {
    return this.db
      .collection(collection)
      .doc(data.payload.doc.id)
      .delete();
  }
  deleteFromID(collection: string, data) {
    return this.db
      .collection(collection)
      .doc(data)
      .delete();
  }
}
