import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavComponent } from "./nav/nav.component";
import { FooterComponent } from "./footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faBook, faBolt, faCogs, faChartLine, faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { AuditComponent } from "./audit/audit.component";
import { CoachingComponent } from "./coaching/coaching.component";
import { TeammateComponent } from "./teammate/teammate.component";
import { TeamComponent } from "./team/team.component";
import { VisioComponent } from "./visio/visio.component";
import { PartenairesComponent } from "./partenaires/partenaires.component";
import { AdminComponent } from "./admin/admin.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

import { environment } from "src/environments/environment";

import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { PressComponent } from "./press/press.component";
import { FormationsComponent } from "./formations/formations.component";
import { MentionsComponent } from "./mentions/mentions.component";
import { PolitiqueComponent } from "./politique/politique.component";
import { PhototekComponent } from "./phototek/phototek.component";
import { EditFormationsComponent } from "./components/edit-formations/edit-formations.component";
import { CgvComponent } from "./cgv/cgv.component";
import { RseComponent } from './components/rse/rse.component';
import { DatesComponent } from './components/dates/dates.component';
import { ParcoursComponent } from './components/parcours/parcours.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    HomeComponent,
    AuditComponent,
    CoachingComponent,
    TeammateComponent,
    TeamComponent,
    VisioComponent,
    PartenairesComponent,
    AdminComponent,
    PressComponent,
    FormationsComponent,
    MentionsComponent,
    PolitiqueComponent,
    PhototekComponent,
    EditFormationsComponent,
    CgvComponent,
    RseComponent,
    DatesComponent,
    ParcoursComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faBook, faBolt, faCogs, faChartLine, faQuoteLeft);
  }
 }
