import { Component, OnInit } from "@angular/core";
import { FireservService } from "../fireserv.service";

@Component({
  selector: "app-phototek",
  templateUrl: "./phototek.component.html",
  styleUrls: ["./phototek.component.css"]
})
export class PhototekComponent implements OnInit {

  constructor(public firebaseService: FireservService) { }

  public phototek;

  ngOnInit() {
    this.phototek = [];

    this.firebaseService.getPeople(this.phototek, "/phototek").then(data => data.map(x => {
      console.log(x.payload.doc.data());
    }));
  }

}
