import { Component, OnInit } from "@angular/core";
import { FireservService } from "../fireserv.service";

interface Activity {
  icon: string;
  title: string;
  text: string;
}

interface About {
  parafs: string[];
  pic: string;
}

interface Atelier {
  title: string;
  name: string;
  pic: string;
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  activities: Activity[];
  about: About;
  ateliers: Atelier[];
  intro: any;

  constructor(public firebaseService: FireservService) {}

  ngOnInit() {
    this.activities = [
      {
        icon: "book",
        title: "Formation",
        text: "Entreprendre une formation enrichit votre potentiel. ",
      },
      {
        icon: "bolt",
        title: "Coaching",
        text: "Un travail au bénéfice de votre évolution, de la réussite et de la création d'idées.",
      },
      {
        icon: "cogs",
        title: "Diagnostique",
        text:
          "Un état des lieux de votre entreprise, valorisation du potentiel humain pour une orientation vers de nouveaux objectifs.",
      },
      {
        icon: "chart-line",
        title: "Conseil",
        text:
          "Un travail collaboratif et positif en toute circonstance au bénéfice de votre progression et de votre développement.",
      },
    ];
    this.about = {
      parafs: [
        `     
      ConnectoEvolution est synonime de TRUWANT YOANN qui propose une EXPERIENCE unique dans l’univers de la formation.`,
        `
      Pourquoi ConnecToEvolution ? Car le plus beau des cadeaux qu'on puisse se faire est de se connecter a sa propre Evolution`,
        ` 
      Venir faire une formation avec Yoann, c’est avant tout entrer dans un parcours de formation dont vous êtes les ACTEURS.`,
        ` 
      Oui les ACTEURS ! `,
        `
      Venez vibrer et vivre une pédagogie active basée sur l’action et la réflexion avec des stratégies de l’apprentissage issues des sciences humaines pour augmenter vos résultats. `,
        `
      Surtout grâce à une mise en pratique intensive ! `,
        `
      Des parcours 100% interactifs avec des experts dans leurs domaines. `,
        `
      Pas de vidéo pré-enregistrée. `,
        `
      Yoann partage ses valeurs par ses actions en étant au plus près de vous. Cela déploie votre motivation lors de formations uniques en leur genre ! `,
        `
      Êtes-vous prêt à vivre des ateliers de Brain storming pédagogique ? Un jeu de formation où la puissance du groupe fait émerger des idées extraordinaires pour l’ensemble des participants. `,
        `
      Une expérience de formation d’une richesse sans limite pour votre réussite et votre singularité. `,
        `
      L’expérience avec Yoann est basée sur un parcours de formation 100 % interactif découpée en étapes. Cette expérience permet un suivi précis, tout en vous laissant progresser à votre rythme pour tirer un maximum de profit et d’expérience pour acquérir votre autonomie. `,
        `
      Son principes d’action : `,
        `
      -	« Tout pour vos résultats »`,
        `
      -	« Vous faire vivre une expérience de formation où vous êtes l'acteur »`,
      ],
      pic: "../../assets/helloquence.jpg",
    };
    this.ateliers = [];

    this.firebaseService.getPeople(this.ateliers, "/ateliers").then((data) =>
      data.map((x) => {
        console.log(x.payload.doc.data());
      })
    );

    this.initAbout();
    this.initIntro();
  }

  private async initAbout() {
    const aboutArray = [];
    try {
      const data = await this.firebaseService.getPeople(aboutArray, "/aPropos");
      if (data.length > 0) {
        const about = data[0].payload.doc.data();
        console.log(about);
        const parafs = about.content.split("\n");
        this.about.parafs = parafs;
      }
    } catch (error) {
      console.log(error);
    }
  }

  private async initIntro() {
    const introArray = [];
    try {
      const data = await this.firebaseService.getPeople(introArray, "/intro");
      if (data.length > 0) {
        const intro = data[0].payload.doc.data();
        console.log(intro);
        const parafs = intro.content.split("»");
        this.intro = parafs;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
