import { Component, OnInit, Inject } from "@angular/core";
import { FireservService } from "../fireserv.service";
import { DOCUMENT } from "@angular/common";

interface Newfile {
  name?: string;
  pic?: any;
  title?: string;
  url?: string;
}

@Component({
  selector: "app-formations",
  templateUrl: "./formations.component.html",
  styleUrls: ["./formations.component.css"]
})

@Inject(DOCUMENT)

export class FormationsComponent implements OnInit {

  public formations: Newfile[];

  constructor(public firebaseService: FireservService) { }

  ngOnInit() {
    this.formations = [];
    console.log(this.formations);
    this.firebaseService.getPeople(this.formations, "/formations").then(data => console.log("good"));
  }

  gotoTop(tag) {
    const el = document.getElementById(tag);
    el.scrollIntoView();
  }

}
