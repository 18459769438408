import { Component, OnInit } from "@angular/core";
// import { easyrtc } from "../../../easyrtc.js/index.js";

@Component({
  selector: "app-visio",
  templateUrl: "./visio.component.html",
  styleUrls: ["./visio.component.css"]
})
export class VisioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // connect() {
  //   easyrtc.setRoomOccupantListener(this.convertListToButtons);
  //   easyrtc.easyApp("easyrtc.audioVideoSimple", "selfVideo",
  //       ["callerVideo1", "callerVideo2", "callerVideo3"],
  //                   this.loginSuccess, this.loginFailure);
  // }

  // convertListToButtons(roomName, data, isPrimary) {
  //   var otherClientDiv = document.getElementById('otherClients');
  //   otherClientDiv.innerHTML = "";
  //   for(var easyrtcid in data) {
  //       var button = document.createElement('button');

  //       button.onclick = function(easyrtcid) {
  //           return function() {
  //               this.performCall(easyrtcid);
  //           };
  //       }(easyrtcid);

  //       var label = document.createTextNode(easyrtcid);
  //       button.appendChild(label);
  //       otherClientDiv.appendChild(button);
  //   }
  // }

  // performCall(otherEasyrtcid) {
  //   var successCB = function() {};
  //   var failureCB = function() {};
  //   easyrtc.call(otherEasyrtcid, successCB, failureCB);
  // }

  // loginSuccess(easyrtcid) {
  //   easyrtc.showError("none", "Successfully connected");
  // }

  // loginFailure(errorCode, message) {
  //   easyrtc.showError(errorCode, message);
  // }
}
