import { Component, OnInit } from '@angular/core';
import { FireservService } from 'src/app/fireserv.service';

@Component({
  selector: 'app-dates',
  templateUrl: './dates.component.html',
  styleUrls: ['./dates.component.css']
})
export class DatesComponent implements OnInit {

  public formations;

  constructor(public fireserv: FireservService) { }

  ngOnInit(): void {
    this.fireserv.getSnapshot("/dates").subscribe(res => {
      this.formations = res;
      this.formations.map(x => console.log(x.payload.doc.data()))
    });
  }

}
