import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-press",
  templateUrl: "./press.component.html",
  styleUrls: ["./press.component.css"]
})
export class PressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
