import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { AngularFirestore } from "angularfire2/firestore";
import { FireservService } from "../fireserv.service";


interface Detail {
  main: string;
  sec: string;
}

interface Profiles {
  classes: string;
  content: string[];
}

interface Mate {
  name: string;
  pic: File;
  title: string;
  desc: string;
  details: Detail[];
  profiles: Profiles;
  comps: Profiles;
  certifs: Profiles;
  formations: Profiles;
}

@Component({
  selector: "app-team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.css"]
})

@Inject(DOCUMENT)

export class TeamComponent implements OnInit {

  public mates: Mate[];

  constructor(db: AngularFirestore, public firebaseService: FireservService) {}

  ngOnInit() {

    this.mates = [];

    this.firebaseService.getPeople(this.mates, "/mates").then(data => {
      data.map(x => console.log(x.payload.doc.data()));
      console.log(this.mates);
    });

  }

  gotoTop(tag) {
    const el = document.getElementById(tag);
    el.scrollIntoView();
  }

}


// this.mates = [
    //   {
    //     name: "Yohan Truwant",
    //     pic: "yohan.jpg",
    //     title: "Formateur et Coach Professionnel. Gérant de ConnectoEvolution",
    //     desc: [`Quand vous vous inscrivez dans une démarche évolutive, vous acquerrez de l’expérience,
    //     de la réflexion et de la connaissance. Ce savoir-faire, que vous vous appropriez, développe votre autonomie.
    //     Engagé dans une démarche active de développement personnel en continu,
    //     grâce à des échanges et partages de connaissances réguliers avec d’autres formateurs et coachs professionnels,
    //      nous privilégions les rapports collaboratifs.
    //      Cela permet une mise en mouvement et une évolution cognitive de chacun,
    //      que ce soit dans le cadre d’un accompagnement personnalisé ou dans le cadre d’échanges interprofessionnels.
    //      L’exercice de co-construction est un outil dont la mécanique stimule l’action,
    //      comme nous sommes constamment amenez à le faire dans les relations humaines.`],
    //     details: [
    //       {
    //         main: "Pour vous accompagner :",
    //         sec: "« Bienveillance, ouverture, partage, développement, dynamisme et confidentialité. »"
    //       },
    //       {
    //         main: "Ma devise personnelle de tous les jours : ",
    //         sec: "« Bien manger, bien boire et bien se connaître ! »"
    //       }
    //     ],
    //     profiles: {
    //       classes: "hide",
    //       content: [
    //         "Fonction: Formateur et Coach Professionnel, gérant de ConnectoEvolution.",
    //         "Expérience : Formateur depuis 15 ans. Coach depuis 6 ans. Responsable d’équipe 15 ans. ",
    //         `Services : Accompagnement dans le changement et analyse transactionnelle, stratégie commerciale,
    //         risques psycho-sociaux, formation en développement des compétences managériales,
    //          audit, conseil, Spécialiste du monde viticole. `
    //       ]
    //     },
    //     comps: {
    //       classes: "hide",
    //       content: ["Accompagnement de type coaching, conseil, managérial",
    //       "Accompagnement pour prévenir les risques psychosociaux",
    //       "Formation sur la posture de dirigeant ou de responsable",
    //       "Conception de plan de formation et son suivi",
    //       "Élaboration de stratégies marketing et commerciales",
    //       "Spécialiste dans la cohésion de groupe et l’animation d’un réseau",
    //       "Techniques de prospection commerciales et de vente",
    //       "Grandes connaissances culinaires, œnologiques et dans l’organisation de dégustations,"],
    //     },
    //     certifs: {
    //       classes: "hide",
    //       content: [""]
    //     },
    //     formations: {
    //       classes: "hide",
    //       content: [""]
    //     },
    //   }
    // ];
