import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teammate',
  templateUrl: './teammate.component.html',
  styleUrls: ['./teammate.component.css']
})
export class TeammateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
