// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAgWG4hVvmDPLbZApBwYAaz16rpuE2R9o4",
    authDomain: "connectoevolution.firebaseapp.com",
    databaseURL: "https://connectoevolution.firebaseio.com",
    projectId: "connectoevolution",
    storageBucket: "connectoevolution.appspot.com",
    messagingSenderId: "760210922821",
    appId: "1:760210922821:web:26728c51bbcbcabf48722c",
    measurementId: "G-KV2VJKMDEX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
