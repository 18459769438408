import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FireservService } from "../fireserv.service";

interface Detail {
  main: string;
  sec: string;
}

interface Profiles {
  classes: string;
  content: string[];
}

interface Newfile {
  name?: string;
  pic?: any;
  title?: string;
  url?: string;
}

interface MateSingle {
  name: string;
  pic: any;
  title: string;
  desc: string;
  details: string;
  profiles: string;
  comps: string;
  certifs: string;
  formations: string[];
}

interface UserFileUpload {
  path?: string;
  file?: File;
}

interface Profiles {
  classes: string;
  content: string[];
}

interface Mate {
  name: string;
  pic: File;
  title: string;
  desc: string;
  details: Detail[];
  profiles: Profiles;
  comps: Profiles;
  certifs: Profiles;
  formations: Profiles;
}

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"]
})

export class AdminComponent implements OnInit {

  public mates: Mate[];
  public formations: Newfile[];
  public parcours: Newfile[];
  public ateliers: Newfile[];
  public formationLinks: string[];
  public formationLinksEdit;

  public userFileUpload: UserFileUpload;
  public formFileUpload: UserFileUpload;
  public rseFileUpload: UserFileUpload;
  public phototekFileUpload: UserFileUpload;
  public atelierFileUpload: UserFileUpload;
  public partnerFileUpload: UserFileUpload;

  public exampleForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    desc: new FormControl(),
    details: new FormControl(),
    profiles: new FormControl(),
    comps: new FormControl(),
    pic: new FormControl(),
    certifs: new FormControl(),
    formations: new FormControl(),
   });

  public editUserForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    desc: new FormControl(),
    details: new FormControl(),
    profiles: new FormControl(),
    comps: new FormControl(),
    pic: new FormControl(),
    certifs: new FormControl(),
    formations: new FormControl(),
   });

  public formationForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });
  public parcoursForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });

  public datesAdd: FormGroup;

  public rseForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });
  public editAtelierForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });
  public atelierForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });
  public phototekForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
   });
  public partnerForm = new FormGroup({
    name: new FormControl(),
    title: new FormControl(),
    pic: new FormControl(),
    site: new FormControl(),
    fb: new FormControl(),
    viadeo: new FormControl(),
    linkedin: new FormControl(),
    instagram: new FormControl(),
   });

   public formationDates;
   public currDates;
   public datesEdit: FormGroup;
   public rse;

  constructor(public firebaseService: FireservService) {}

  ngOnInit() {

    this.formations = [];
    this.parcours = [];
    this.mates = [];
    this.ateliers = [];
    this.formationLinks = [];
    this.userFileUpload = {};
    this.formFileUpload = {};
    this.rseFileUpload = {};
    this.atelierFileUpload = {};
    this.partnerFileUpload = {};
    this.phototekFileUpload = {};

    this.formationLinksEdit = {};

    this.firebaseService.getSnapshot("/dates").subscribe(res => this.formationDates = res);
    this.firebaseService.getSnapshot("/rse").subscribe(res => this.rse = res);

    this.firebaseService.getPeople(this.formations, "/formations");
    this.firebaseService.getPeople(this.parcours, "/parcours");
    this.firebaseService.getPeople(this.mates, "/mates", true).then(() => {
      this.mates.forEach(mate => {
        if (mate.details.length > 0) {
          const newDetails = [];
          mate.details.map(x => newDetails.push(`${x.main}: ${x.sec}`));
          mate.details = newDetails;
        } else {mate.details = []; }
      });
    });
    this.firebaseService.getPeople(this.ateliers, "/ateliers", true);

  }

  createUser(data: MateSingle, event: Event) {
    console.log(data);
    event.preventDefault();
    this.firebaseService.createUser({
      name: data.name,
      pic: this.userFileUpload,
      title: data.title,
      desc: data.desc,
      details: data.details,
      profiles: data.profiles,
      comps: data.comps,
      certifs: data.certifs,
      formations: this.formationLinks,
    });
  }

  createFormation(data, event: Event) {
    event.preventDefault();
    this.firebaseService.createFormation({
      name: data.name,
      pic: this.formFileUpload,
      title: data.title
    });
  }
  createparcours(data, event: Event) {
    event.preventDefault();
    this.firebaseService.createParcours({
      name: data.name,
      pic: this.formFileUpload,
      title: data.title
    });
  }
  createRse(data, event: Event) {
    event.preventDefault();
    this.firebaseService.createRse({
      name: data.name,
      pic: this.rseFileUpload,
      title: data.title
    });
  }

  createatelier(data , event: Event) {
    event.preventDefault();
    this.firebaseService.createatelier({
      name: data.name,
      pic: this.atelierFileUpload,
      title: data.title
    });
  }

  createphototek(data , event: Event) {
    event.preventDefault();
    this.firebaseService.addPhototek({
      name: data.name,
      pic: this.phototekFileUpload,
      title: data.title
    });
  }

  createPartner(data , event: Event) {
    event.preventDefault();
    const tosave = data;
    tosave.pic = this.partnerFileUpload;
    this.firebaseService.createPartner(data);
  }

  upload(event, ref: UserFileUpload, path: string) {
    console.log(ref);
    ref.path = `${path}/${Date.now()}_${event.target.files[0].name}`;
    ref.file = event.target.files[0];
  }

  reset(form) {
    form.reset();
  }

  log(el, item) {
    const elem = el.target.value;
    if (el.target.checked === true) {
      this.formationLinks.push(item);
      console.log(this.formationLinks);
    } else {
      const index = this.formationLinks.indexOf(item, 0);
      if (index > -1) { this.formationLinks.splice(index, 1); }
      console.log(this.formationLinks);
    }
  }

  editlog(el, item, id) {
    const elem = el.target;
    if (elem.checked === true) {
      if (!this.formationLinksEdit[id]) { this.formationLinksEdit[id] = []; }

      this.formationLinksEdit[id].push(item);
      console.log(this.formationLinksEdit);
    } else {
      const index = this.formationLinksEdit[id].indexOf(item, 0);
      if (index > -1) { this.formationLinksEdit[id].splice(index, 1); }
      console.log(this.formationLinksEdit);
    }
  }

  editUser(id, value, event, user) {
    event.preventDefault();

    const data = {
      certifs: value.certifs !== null ? value.certifs : user.certifs ,
      comps: value.comps !== null ? value.comps : user.comps ,
      desc: value.desc !== null ? value.desc : user.desc ,
      details: value.details !== null ? value.details.split("\n") : user.details ,
      formations: this.formationLinksEdit[id] ? this.formationLinksEdit[id] : user.formations,
      name: value.name !== null ? value.name : user.name ,
      pic: this.userFileUpload.file ? this.userFileUpload : user.pic,
      profiles: value.profiles !== null ? value.profiles : user.profiles ,
      title: value.title !== null ? value.title : user.title ,
    };

    console.log({data, value});

    return this.firebaseService.editUser(id, data);
  }
  editAtelier(id, value, event) {
    event.preventDefault();
    console.log(value);

    return this.firebaseService.editAtelier(id, {
      name: value.name,
      pic: this.atelierFileUpload,
      title: value.title
    });
  }

  activateDatesAdd() {
    this.datesAdd = new FormGroup({
      title: new FormControl(""),
      dates: new FormControl("")
    });
  }

  addDates(event, data): void {
    event.preventDefault();

    this.firebaseService.create("/dates", data).then(res => this.datesAdd = null);
  }

  editDatesSubmit(event, doc, data): void {
    event.preventDefault();

    this.firebaseService.update("/dates", doc, data).then(res => {
      this.currDates = null;
      this.datesEdit = null;
    });
  }

  editDates(e, data: any): void {
    e.preventDefault();

    this.datesEdit = new FormGroup({
      title: new FormControl(data.payload.doc.data().title),
      dates: new FormControl(data.payload.doc.data().dates),
    });

    this.currDates = data.payload.doc.id;
  }

  deleteDates(doc): void {
    this.firebaseService.delete("/dates", doc).then(res => console.log("done"));
  }

  delete(collection, doc) { this.firebaseService.deleteFromID(collection, doc).then(res => console.log("done")); }

}
