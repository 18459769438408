import { Component, OnInit, HostListener } from "@angular/core";

interface Item {
  title: string;
  route?: string;
  classes ?: string;
}

@Component({
  selector: "app-nav",
  templateUrl: "./nav.component.html",
  styleUrls: ["./nav.component.css"]
})


export class NavComponent implements OnInit {

  items: Item[];
  public getadmin: boolean;
  public getKeyadmin: boolean;
  public count: number;

  constructor() { }

  ngOnInit() {
    this.items = [
      {title: "ACCUEIL", route: ""},
      {title: "YOANN", route: "team"},
      {title: "LES DATES", route: "dates"},
      {title: "FORMATION", route: "formation"},
      // {title: "PARCOURS CRÉATEUR", route: "parcours"},
      // {title: "PARTENAIRES", route: "partenaires"},
      {title: "RSE/QUALIOPI", route: "rse"},
    ];

    this.getadmin = false;
    this.getKeyadmin = false;

    this.count = 0;
  }

  @HostListener("window:keydown", ["$event"])
  adminKey(event: KeyboardEvent) {
    if (this.getKeyadmin === true) {
      const keys = ["Shift", "Shift", "c", "o", "n", "n", "e", "c", "t"];

      if (event.key === keys[this.count]) {
        this.count += 1;
        if (this.count === keys.length) { this.getadmin = true; this.count = 0; }
      } else { this.count = 0; }
    }
  }
  adminMouse() { this.getKeyadmin = true; }

}
